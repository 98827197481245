import {useStorage} from '@vueuse/core';
import {defineStore} from 'pinia';
import GlobalSettingsService from "../services/GlobalSettingsService.js";

const loadGlobalSettings = async (orgCode, globalSettingsName) => {
    let settingsValue = null
    try {
        const globalSettingsService = new GlobalSettingsService();
        let resp = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, globalSettingsName);
        if (resp.success) {
            settingsValue = resp.result.settingValue;
        }
    } catch (error) {
        settingsValue = null;
    }
    return settingsValue;
}

export const useGlobalSettingsStore = defineStore({
    id: 'globalSettings',
    state: () => ({
        printHeadingOne: useStorage('printHeadingOne', null),
        printHeadingTwo: useStorage('printHeadingTwo', null),
        paperSizeForWeb: useStorage('paperSizeForWeb', null),
        displayOrgLogoOnPaper: useStorage('displayOrgLogoOnPaper', null),
        showHsCodeBeforeItemInPrint: useStorage('showHsCodeBeforeItemInPrint', null),
        showProductCodeInPrint: useStorage('showProductCodeInPrint', null),
        allowNegativeStockInSalesPrint: useStorage('allowNegativeStockInSalesPrint', null),
        enableAutoPrinting: useStorage('enableAutoPrinting', null),
        abbreviatedTaxInvoiceLimit: useStorage('abbreviatedTaxInvoiceLimit', null),
        showDiscountAmountInSalesPrint: useStorage('showDiscountAmountInSalesPrint', null),
    }),
    getters: {
        getHeadingOne: (state) => {
            return state.printHeadingOne === undefined ? null : state.printHeadingOne;
        },
        getHeadingTwo: (state) => {
            return state.printHeadingTwo === undefined ? null : state.printHeadingTwo;
        },
        getPaperSize: (state) => {
            return state.paperSizeForWeb === undefined ? null : state.paperSizeForWeb;
        },
        getPaperToDisplayOrgLogo: (state) => {
            return state.displayOrgLogoOnPaper === undefined ? null : state.displayOrgLogoOnPaper;
        },
        getDisplayOrgLogo: (state) => {
            return (paperSize) => paperSize === null || state.displayOrgLogoOnPaper === undefined || state.displayOrgLogoOnPaper === null || state.displayOrgLogoOnPaper === 'NONE'
                ? false : state.displayOrgLogoOnPaper.includes(paperSize);
        },
        getShowHsCodeBeforeItem: (state) => {
            return state.showHsCodeBeforeItemInPrint === undefined ? null : state.showHsCodeBeforeItemInPrint;
        },
        getShowProductCode: (state) => {
            return state.showProductCodeInPrint === undefined ? null : state.showProductCodeInPrint;
        },
        getAllowNegativeStock: (state) => {
            return state.allowNegativeStockInSalesPrint === undefined ? null : state.allowNegativeStockInSalesPrint;
        },
        getEnableAutoPrinting: (state) => {
            return state.enableAutoPrinting == null ? null : state.enableAutoPrinting;
        },
        getAbbreviatedTaxInvoiceLimit: (state) => {
            return state.abbreviatedTaxInvoiceLimit == null ? null : state.abbreviatedTaxInvoiceLimit;
        },
        getShowDiscountAmountInSalesPrint: (state) => {
            return state.showDiscountAmountInSalesPrint == null ? null : state.showDiscountAmountInSalesPrint;
        },

    },

    actions: {
        async loadAllGlobalSettings(orgCode) {
            console.log("Started Reloading print store ------------")
            this.clearGlobalStore()
            this.loadHeadingOne(orgCode)
            this.loadHeadingTwo(orgCode);
            this.loadPaperSizeForWeb(orgCode)
            this.loadShowHSCodeBeforeItemInPrint(orgCode)
            this.loadShowProductCodeInPrint(orgCode)
            this.loadAllowNegativeStock(orgCode)
            this.loadEnableAutoPrinting(orgCode)
            this.loadAbbreviatedTaxInvoiceLimit(orgCode)
            this.loadShowDiscountAmountInSalesPrint(orgCode)
            console.log("Completed Reloading print store ------------")
        },
        async loadHeadingOne(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "PRINT_HEADING_ONE");
            this.printHeadingOne = settingsValue;
        },
        async loadHeadingTwo(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "PRINT_HEADING_TWO");
            this.printHeadingTwo = settingsValue;
        },
        async loadPaperSizeForWeb(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "PAPER_SIZE_FOR_WEB");
            this.paperSizeForWeb = settingsValue;
        },
        async loadDisplayOrgLogoOnPaper(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "DISPLAY_ORGANIZATION_LOGO_ON_PAPER");
            this.displayOrgLogoOnPaper = settingsValue;
        },

        async loadShowHSCodeBeforeItemInPrint(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_HS_CODE_BEFORE_ITEM_IN_PRINT");
            this.showHsCodeBeforeItemInPrint = settingsValue != null ? (settingsValue.toLowerCase() === "true" ? true : false) : false;
        },
        async loadShowProductCodeInPrint(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_PRODUCT_CODE_IN_PRINT");
            this.showProductCodeInPrint = settingsValue != null ? (settingsValue.toLowerCase() === "true" ? true : false) : false;
        },
        async loadAllowNegativeStock(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "ALLOW_NEGATIVE_STOCK");
            this.allowNegativeStockInSalesPrint = settingsValue != null ? (settingsValue.toLowerCase() === "true" ? true : false) : false;
        },
        async loadEnableAutoPrinting(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "ENABLE_AUTO_PRINTING");
            this.enableAutoPrinting = settingsValue != null ? (settingsValue.toLowerCase() === "true" ? true : false) : false;
        },
        async loadAbbreviatedTaxInvoiceLimit(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "ABBREVIATED_TAX_INVOICE_LIMIT");
            this.abbreviatedTaxInvoiceLimit = settingsValue != null ? Number(settingsValue) : 0.0;
        },
        async loadShowDiscountAmountInSalesPrint(orgCode) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_DISCOUNT_IN_SALES_PRINT");
            this.showDiscountAmountInSalesPrint = settingsValue != null ? (settingsValue.toLowerCase() === "true" ? true : false) : false;
        },

        async clearGlobalStore() {
            this.printHeadingOne = null;
            this.printHeadingTwo = null;
            this.organizationLogo = null;
            this.paperSizeForWeb = null;
            this.displayOrgLogoOnPaper = null;
            this.termsAndConditions = [];
            this.showHsCodeBeforeItemInPrint = null;
            this.showProductCodeInPrint = null;
            this.allowNegativeStockInSalesPrint = null;
            this.enableAutoPrinting = null;
            this.abbreviatedTaxInvoiceLimit = null
            this.showDiscountAmountInSalesPrint = null
        },
    }
});